<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Roles/List</h4>
          <div>
            <router-link :to="can('role-create') ? {name:'appRoleCreate'} : '#'">
              <span :title="can('role-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1">
          <div class="row border rounded py-2 mb-2 align-items-center">
            <div class="col-12 col-md-6 mb-1 mb-lg-0">
              <label for="search">Search</label>
              <input v-model="getRolesParams.name" class="form-control search-product-input-element" id="search" type="text"
                     placeholder="Search by name"/>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-1 mb-lg-0">
              <label>Type</label>
              <VueMultiselect :allowEmpty="false" v-model="selectedRoleType" class="" :options="roleTypes"
                              :close-on-select="true" placeholder="Select status" label="name" track-by="value"
                              :show-labels="false"/>
            </div>
            <div class="col-12 col-md-3 mb-1 mb-lg-0">
              <button @click="applyRoleFilter(null)"  type="button"
                      class="btn btn-primary btn-block glow users-list-clear mb-0">Search
              </button>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">

              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '30%' }">NAME</th>
                    <th class="position-relative" :style="{ width: '30%' }">TYPE</th>
                    <th class="position-relative" :style="{ width: '30%' }">DESCRIPTION</th>
                    <th class="position-relative" :style="{ width: '10%' }">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(role, key) in roles" :key="key">
                    <td class="small-badge"><span>{{ role.name }}</span></td>
                    <td><span>{{ role.type }}</span></td>
                    <td><span>{{ role.description }}</span></td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <router-link :to="can('role-view') ? {name: 'appRoleView',params: { id: role.id }} : '#'"
                                     :title="can('role-view') ? 'View' : 'View Forbidden'"
                                     :style="{ height: '18px' }"
                                     class="view-edit-delete-icon">
                          <div class="cursor-pointer view-edit-delete-icon" :style="{ height: '18px' }">
                            <i class="bx bx-show"></i>
                          </div>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <router-link :to="can('role-update') ? {name: 'appRoleEdit',params: { id: role.id }} : '#'"
                                         :title="can('role-view') ? 'Update' : 'Update Forbidden'"
                                         class="dropdown-item">
                              Update
                            </router-link>
                            <button class="dropdown-item"
                                    :disabled="!can('role-delete')"
                                    data-toggle="modal" data-target="#roleDeleteAlertModal"
                                    @click="this.modelIdForDelete = role.id">
                              Delete
                            </button>
                          </div>
                        </div>

                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyRoleFilter"/>
              </div>
            </div>
          </div>
        </div>
        <!-- role details modal -->
        <RoleDeleteAlertModal modal-name="roleDeleteAlertModal" :model-id="modelIdForDelete" model-name="role"
                                @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import RoleDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';


// mixins
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
//core packages
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RoleList",
  components: {
    AppLayout,
    ListPagination,
    RoleDeleteAlertModal,
  },
  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      modelIdForDelete: '',
      getRolesParams: {
        type: '',
        name: '',
        order_by_name: 'ASC',
        paginate: 1,
        pagination: '',
      },
      selectedRoleType: {
        value: '',
        name: "Any",
      },
    };
  },

  computed: {
    ...mapGetters({
      roleTypes: 'roleTypes',
      roles: "appRoles/roles",
      paginateLinks: "appRoles/paginateLinks",
    }),

  },
  watch: {
    selectedRoleType(selectedRoleType) {
      this.getRolesParams.type = selectedRoleType.value;
    },
  },
  methods: {
    ...mapActions({
      getRoles: "appRoles/getRoles",
      deleteRoleOnList: 'appRoles/deleteRoleOnList',

    }),

    async deleteSingleRoleOnLIst(id) {
      this.deleteRoleOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Role deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },


    async getRoleList() {
      await this.getRoles(this.getRolesParams).then(async (response) => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async applyRoleFilter() {
      await this.loader(true);
      await this.getRoleList();
      await this.loader(false);
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleRoleOnLIst(confirmModelDeletion.modelId);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getRoleList();
    await this.loader(false);
  },
};
</script>

<style scoped>
</style>
